<template>
  <div class="am-bd">
    <ul class="artilce-list">
      <li v-for="(article,index) in articles" v-bind:key="index">
        <h3>
          <router-link :to="{path:'/article/view',query:{id:article.id}}" target="_blank" tag="a">
            <span>{{article.title}}</span>
          </router-link>
        </h3>
      </li>
    </ul>
  </div>
</template>

<script>
import {getArticlePaging} from '@/api/article'

export default {
  name: "Page",
  data() {
    return {
      articles:[]
    }
  },
  watch: {
    '$route.query.categoryId': function(){
      // 只要categoryId的值发生变化,这个方法就会被调用
      // this.list.categoryId=this.$route.query.categoryId
      //重新调用请求数据的方法，刷新页面数据
      this.getArticleList()
    }
  },
  created() {
    this.getArticleList()
  },
  methods:{
    getArticleList(){
      getArticlePaging({
        pageSize: 10,
        currentPage:1,
        order:'id asc',
        categoryId:this.$route.query.categoryId
      }).then(res => {
        this.articles = res.data.list
      })
    }
  }
}
</script>

<style scoped>

</style>
